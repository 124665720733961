<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M10.6668 31.6668L8.3335 29.3335L17.6668 20.0002L8.3335 10.6668L10.6668 8.3335L20.0002 17.6668L29.3335 8.3335L31.6668 10.6668L22.3335 20.0002L31.6668 29.3335L29.3335 31.6668L20.0002 22.3335L10.6668 31.6668Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
